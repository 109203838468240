import { object, string, number } from 'yup';
import { FIELDS } from '../types';
import {
  DebtRangeDto,
  RiskGroupDefinitionState,
  TradeCreditLimitDefinitionState,
  TradeCreditLimitRangeDto
} from 'api/models';

export const reportSchema = object().shape({
  [FIELDS.NAME]: string().max(100, 'Maksymalna ilość znaków to 100').trim().required('Pole jest wymagane.'),
  [FIELDS.FREQUENCY]: number().min(1).required('Pole jest wymagane.'),
  [FIELDS.DETAILED_COUNTERPARTY_LIST]: number().min(1).required('Pole jest wymagane.'),
  [FIELDS.RISK_GROUP_DEFINITION_STATE]: number().when(
    [FIELDS.RISK_GROUPS, FIELDS.RISK_GROUP_USE_DECLINE_FILTER, FIELDS.RISK_GROUP_USE_IMPROVEMENT_FILTER],
    {
      is: (riskgroups: number[], decline: boolean, improvement: boolean) => {
        return riskgroups.length === 0 && !decline && !improvement;
      },
      then: (schema) =>
        schema.test('isCustomDefinition', 'Zaznacz przynajmniej jedną opcję poniżej.', (value) => {
          return value !== Number(RiskGroupDefinitionState.CustomDefinition);
        }),
      otherwise: (schema) => schema
    }
  ),
  [FIELDS.RISK_GROUP_IMPROVEMENT_THRESHOLD]: string().when(
    [FIELDS.RISK_GROUP_DEFINITION_STATE],
    (riskGroupDefinitionState, schema) =>
      riskGroupDefinitionState === RiskGroupDefinitionState.CustomDefinition ? schema.required() : schema
  ),
  [FIELDS.RISK_GROUP_DECLINE_THRESHOLD]: string().when(
    [FIELDS.RISK_GROUP_DEFINITION_STATE],
    (riskGroupDefinitionState, schema) =>
      riskGroupDefinitionState === RiskGroupDefinitionState.CustomDefinition ? schema.required() : schema
  ),
  [FIELDS.TRADE_CREDIT_LIMIT_DEFINITION_STATE]: number().when(
    [
      FIELDS.TRADE_CREDIT_LIMIT_RANGE_FILTER,
      FIELDS.TRADE_CREDIT_LIMIT_USE_DECLINE_THRESHOLD,
      FIELDS.TRADE_CREDIT_LIMIT_USE_IMPROVEMENT_THRESHOLD
    ],
    {
      is: (ranges: TradeCreditLimitRangeDto[], decline: boolean, improvement: boolean) => {
        return ranges.length === 0 && !decline && !improvement;
      },
      then: (schema) =>
        schema.test('isCustomDefinition', 'Zaznacz przynajmniej jedną opcję poniżej.', (value) => {
          return value !== Number(TradeCreditLimitDefinitionState.CustomDefinition);
        }),
      otherwise: (schema) => schema
    }
  ),
  [FIELDS.DEBT_DEFINITION_STATE]: number().when(
    [
      FIELDS.DEBT_RANGE_FILTER,
      FIELDS.DEBT_USE_DECLINE_THRESHOLD,
      FIELDS.DEBT_USE_IMPROVEMENT_THRESHOLD,
      FIELDS.DEBT_ADDING_DEBTOR,
      FIELDS.DEBT_REMOVING_DEBTOR
    ],
    {
      is: (ranges: DebtRangeDto[], decline: boolean, improvement: boolean, adding: boolean, removing: boolean) => {
        return ranges.length === 0 && !decline && !improvement && !adding && !removing;
      },
      then: (schema) =>
        schema.test('isCustomDefinition', 'Zaznacz przynajmniej jedną opcję poniżej.', (value) => {
          return value !== Number(TradeCreditLimitDefinitionState.CustomDefinition);
        }),
      otherwise: (schema) => schema
    }
  )
});
